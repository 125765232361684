import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

import { browserState, viewportState } from "../components/new/ScrollAware";
import Layout from "../components/new/Layout";
import FixedBlock from "../components/new/blocks/FixedBlock";
import VideoGridBlock from "../components/new/blocks/VideoGridBlock";
import HomepageHeroBlock from "../components/new/blocks/HomepageHeroBlock";

import { extractSocialMetadata } from "../utils/content";
import theme from "../theme";

const Content = styled.div`
  z-index: 99;
  padding-top: ${(props) => `${props.length * 100}vh`};
`;

const IndexPage = ({ data }) => {
  const content = data.prismic.homepage;
  const meta = data.site.siteMetadata;

  const videoCards = data.prismic.allVideo_cards.edges.sort(
    (a, b) => a.node.featured_order - b.node.featured_order
  );

  const { width, height } = useRecoilValue(viewportState);
  const { isIE } = useRecoilValue(browserState);

  if (!content) {
    return null;
  }

  const [hasSocialMetaData, socialMetadata] = extractSocialMetadata(
    content.body1
  );

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title={content.meta_title}
        meta={[
          {
            name: `description`,
            content: content.meta_description,
          },
          {
            property: `og:title`,
            content: hasSocialMetaData
              ? socialMetadata.general_card.title
              : meta.title,
          },
          {
            property: `og:description`,
            content: hasSocialMetaData
              ? socialMetadata.general_card.description
              : content.meta_description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: hasSocialMetaData && socialMetadata.general_card.image,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: meta.author,
          },
          {
            name: `twitter:site`,
            content: hasSocialMetaData && socialMetadata.twitter_handle,
          },
          {
            name: `twitter:title`,
            content: hasSocialMetaData
              ? socialMetadata.twitter_card.title
              : meta.title,
          },
          {
            name: `twitter:description`,
            content: hasSocialMetaData
              ? socialMetadata.twitter_card.description
              : meta.description,
          },
          {
            name: `twitter:image`,
            content: hasSocialMetaData && socialMetadata.twitter_card.image,
          },
        ].concat(meta)}
      />
      <Layout navigationTransparent={true}>
        {content.body.map((section, i) => {
          const key = `${section.type}_${i}`;
          const { type, primary: data } = section;

          switch (type) {
            case "videoplayer":
              return (
                <FixedBlock
                  key={key}
                  index={theme.zIndex.background - i}
                  isStatic={isIE}
                >
                  <HomepageHeroBlock
                    index={theme.zIndex.background + 100 - i}
                    key={key}
                    heading={data.video_player_heading[0].text}
                    fallback={data.video_player_video_fallback_image}
                    videoUrl={data.video_player_video_source_full.url}
                  />
                </FixedBlock>
              );
            case "video_grid":
              return (
                <VideoGridBlock
                  heading={data.heading}
                  text={data.text}
                  ctaText={data.cta_text}
                  ctaUrl={data.cta_link}
                  category="thought-leadership"
                  type="landscape"
                  videos={videoCards}
                />
              );
          }
        })}
      </Layout>
    </>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

const query = graphql`
  {
    prismic {
      homepage(uid: "home", lang: "en-gb") {
        meta_description
        meta_title
        body {
          ... on PRISMIC_HomepageBodyVisualblock {
            type
            label
            primary {
              visual_block_variant
              visual_block_split_screen
              visual_block_heading
              visual_block_heading_2
              visual_block_cta_copy
              visual_block_cta_link_target
              visual_block_video_fallback_image {
                ... on PRISMIC__ImageLink {
                  url
                }
              }
            }
          }
          ... on PRISMIC_HomepageBodyVideoplayer {
            type
            label
            primary {
              video_player_heading
              video_player_video_fallback_image {
                ... on PRISMIC__ImageLink {
                  _linkType
                  url
                }
              }
              video_player_video_source_preview {
                ... on PRISMIC__FileLink {
                  _linkType
                  url
                }
              }
              video_player_video_source_full {
                ... on PRISMIC__FileLink {
                  _linkType
                  url
                }
              }
            }
          }
          ... on PRISMIC_HomepageBodyVideo_grid {
            type
            label
            primary {
              cta_link
              cta_text
              heading
              text
            }
          }
        }
        body1 {
          ... on PRISMIC_HomepageBody1General_card {
            type
            label
            primary {
              description
              image
              title
            }
          }
          ... on PRISMIC_HomepageBody1Twitter_card {
            type
            label
            primary {
              card_type
              description
              image
              title
              twitter_handle
            }
          }
        }
      }
      allVideo_cards(
        lang: "en-gb"
        where: { featured: true, type: "Thought Leadership" }
      ) {
        edges {
          node {
            short_copy
            card_image_landscape
            video_id
            featured_order
            hash_id
          }
        }
      }
    }
    site {
      siteMetadata {
        author
        description
        title
      }
    }
  }
`;

const IndexData = () => (
  <StaticQuery
    query={`${query}`}
    render={(data) => <IndexPage data={data} />}
  />
);

export default IndexData;
