import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Grid, Row, Col } from "react-styled-flexboxgrid";
import { useRecoilValue } from "recoil";

import FullScreenVideo from "../FullScreenVideo";

import Logo from "../../../images/logo_dxtra-health.inline.svg";

import Header from "../typography/Heading";
import ButtonLink from "../ButtonLink";

import play from "../../../images/icons/play.svg";

import {
  browserState,
  viewportState,
  clipState,
  calculateTriangle,
  getWindowScroll,
} from "../ScrollAware";
import Swipe from "../Swipe";

const Container = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  z-index: ${(props) => props.theme.zIndex.section};
  clip-path: url(#overlay-shape);
  background-image: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 -10px;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    margin: 0;
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  z-index: ${(props) => props.theme.zIndex.content};
  pointer-events: auto;
`;

const Content = styled.div``;

const Video = styled.video`
  position: absolute;
  display: block;
  width: auto;
  height: auto;
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
  z-index: ${(props) => props.theme.zIndex.background};
`;

const StyledLogo = styled(Logo)`
  max-width: 332px;
  width: 100%;
  height: auto;
  margin: 12px 0;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    max-width: 441px;
    margin: 40px 0 28px;
  }
`;

const PlayButton = styled.button`
  height: 80px;
  width: 80px;
  border-radius: 60px;
  background-color: ${(props) => props.theme.colors.white};
  background-image: url(${play});
  background-size: 100%;
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;
  outline: 0;
  border: 0;
  transition: transform 100ms ease-in-out;
  margin: 0 auto;

  &:hover {
    transform: scale(1.1);
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    margin: 0;
  }

  @media (min-width: ${(props) => props.theme.desktop}px) {
    height: 120px;
    width: 120px;
  }
`;

const videoTypes = {
  mp4: "video/mp4",
  webm: "video/webm",
};

const DXTRA_ANGLE = 31;

const HomepageHeroBlock = ({ heading, fallback, videoUrl }) => {

  const browserWindow = typeof window !== "undefined" ? window : undefined;
  const { width, height } = useRecoilValue(viewportState);
  const {
    isIE,
    isMobile,
    isChrome,
    isRetina,
    isSafari,
    useFallbacks,
  } = useRecoilValue(browserState);
  const [showVideo, setShowVideo] = useState(false);
  const logoRef = useRef();
  const contentRef = useRef();

  const startVideo = () => {
    setShowVideo(true);
  };

  const stopVideo = () => {
    setShowVideo(false);
  };

  return (
    <Container background={fallback.url}>
      <ContentContainer>
        <Content ref={contentRef}>
          <Grid>
            <Row>
              <Col xs={12} md={10} mdOffset={1} lg={9} lgOffset={2}>
                <Header light size="m" as="h3">
                  Introducing
                </Header>

                <div ref={logoRef}>
                  <StyledLogo />
                </div>

                <Header light as="h2" margin="0 0 xxxl">
                  {heading}
                </Header>

                <PlayButton onClick={startVideo} />
              </Col>
            </Row>
          </Grid>
        </Content>

        {showVideo && (
          <FullScreenVideo
            video={{url: videoUrl}}
            onClick={stopVideo}
            playing={showVideo}
          />
        )}

      </ContentContainer>
    </Container>
  );
};

HomepageHeroBlock.propTypes = {
  fallback: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  videoUrl: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
};

export default HomepageHeroBlock;
